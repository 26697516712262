import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import { AttachMoneyOutlined } from '@mui/icons-material';
import { differenceInSeconds } from "date-fns";

export const formatMoney = (amount) => `$ ${numberWithCommas(Math.abs(amount).toFixed(2))}`;

export const numberWithCommas = (number) => {
  return parseFloat(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

function currentYPosition(elm) {
  if (!window && !elm) return;

  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function debounce(fn, duration) {
    let timerId;

    return function (...args) {
        clearTimeout(timerId);

        timerId = setTimeout(() => {
            fn(...args);
        }, duration);
    }
}

export function getIconForCategory(paymentCategory) {
  switch (paymentCategory) {
    case 'Groceries':
      return <ShoppingCartIcon />;
    case 'Transportation':
      return <DirectionsCarIcon />;
    case 'Restaurants':
      return <RestaurantIcon />;
    case 'Utilities':
      return <LightbulbIcon />;
    case 'Accommodation':
      return <HouseOutlinedIcon />
    case 'Subscriptions':
      return <SubscriptionsOutlinedIcon />
    case 'Exercise':
      return <FitnessCenterOutlinedIcon />
    default:
      return <AttachMoneyOutlined />;
  }
}

export function delayExecution(fn, timeout) {
    const timerId = setTimeout(fn, timeout);
    return () => clearTimeout(timerId);
}
  
export function interpretFrequency(frequency) {
  const daysOfWeek = {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday'
  };

  // Helper function to add ordinal suffix
  const addOrdinalSuffix = (n) => {
      const s = ["th", "st", "nd", "rd"],
            v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  if (frequency.startsWith("EvryDay")) {
      return "Every day";
  }

  if (frequency.startsWith("EvryWorkgDay")) {
      return "Every working day";
  }

  if (frequency.startsWith("IntrvlWkDay")) {
      const parts = frequency.split(":");
      const interval = parseInt(parts[1], 10);  // interval in weeks
      const dayOfWeek = parseInt(parts[2], 10); // day within the week

      return `Every ${interval} week${interval > 1 ? 's' : ''} on ${daysOfWeek[dayOfWeek]}`;
  }

  if (frequency.startsWith("WkInMnthDay")) {
      const parts = frequency.split(":");
      const weekOfMonth = parseInt(parts[1], 10); // week of the month
      const dayOfWeek = parseInt(parts[2], 10);   // day within the week

      return `On the ${addOrdinalSuffix(weekOfMonth)} week of the month on ${daysOfWeek[dayOfWeek]}`;
  }

  if (frequency.startsWith("IntrvlMnthDay")) {
      const parts = frequency.split(":");
      const interval = parseInt(parts[1], 10);   // interval in months
      const dayOfMonth = parseInt(parts[2], 10); // day within the month

      return `Every ${interval} month${interval > 1 ? 's' : ''} on the ${addOrdinalSuffix(dayOfMonth)} day of the month`;
  }

  return "Unknown frequency";
}
